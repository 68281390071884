import React from "react";
import vars from "../lib/vars";

const Hero = ({ heading, headingAnims, subheading }) => {
  const ReactRotatingText = require("react-rotating-text");

  return (
    <div className="container hero" style={{ "--height": vars["50vh"] }}>
      <h1 className="h1 hero__heading">
        <span className="hero__heading-static">{`${heading} `}</span>
        <ReactRotatingText items={headingAnims} pause={3000} />
      </h1>
      <p className="hero__subheading" dangerouslySetInnerHTML={{ __html: subheading }}></p>
    </div>
  );
};

export default Hero;
