import React from "react";
import useDarkMode from "use-dark-mode";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const Header = () => {
  let shouldUse = typeof window !== "undefined" ? localStorage.darkMode : true;
  const darkMode = useDarkMode(shouldUse);

  return (
    <div className="header container">
      <div className="header__inner">
        <h1 className="h2 header__logo">Lily Fielding</h1>

        <Toggle
          icons={false}
          defaultChecked={darkMode.value}
          className="header__toggle"
          onChange={darkMode.toggle}
        />
      </div>
    </div>
  );
};

export default Header;
