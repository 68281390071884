import React from "react";
import Helmet from "react-helmet";

export default ({
  metaTitle,
  metaDescription,
  metaKeywords,
  openGraphDescription,
  openGraphImage,
  openGraphTitle,
  twitterDescription,
  twitterImage,
  twitterTitle
}) => (
  <Helmet>
    {metaTitle && <title>{metaTitle}</title>}
    {metaDescription && <meta name="description" content={metaDescription} />}
    <meta name="keywords" content={metaKeywords} />

    <meta property="og:url" content="https://www.lilyfielding.co.uk" />
    <meta property="og:title" content={openGraphTitle} />
    <meta property="og:site_name" content="Lily Fielding" />
    <meta property="og:description" content={openGraphDescription} />
    {openGraphImage && <meta property="og:image" content={openGraphImage.asset.url} />}

    <meta name="twitter:site" content="@lilfieldz" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={twitterTitle} />
    <meta name="twitter:description" content={twitterDescription} />
    <meta name="twitter:url" content="https://www.lilyfielding.co.uk" />
    {twitterImage && <meta name="twitter:image:src" content={twitterImage.asset.url} />}
  </Helmet>
);
