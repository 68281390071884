import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Collapse from "@kunukn/react-collapse";

const Project = ({ index, activeProject, setActiveProject, project }) => {
  const { date, description, studio, tags, title, url, url_title, video } = project;
  const [isDesktopActive, setDesktopActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const elVideo = useRef(null);

  let isActiveProject = activeProject === index;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1200);
    });
  }

  const makeActive = (ev) => {
    if (!ev.target.href) {
      isActiveProject ? setActiveProject(null) : setActiveProject(index);
    }
  };

  const showVideo = (ev) => {
    if (isMobile) return;

    const video = elVideo.current;
    setDesktopActive(true);

    video.classList.add("transitioning");
    setTimeout(() => video.classList.add("active"), 300);
  };

  const moveVideo = (ev) => {
    if (isMobile) return;

    const video = elVideo.current;
    video.style.left = ev.pageX + "px";
    video.style.top = ev.pageY + "px";
  };

  const hideVideo = (ev) => {
    const video = elVideo.current;
    setDesktopActive(false);

    video.classList.remove("active");
    setTimeout(() => video.classList.remove("transitioning"), 300);
  };

  const showHideVideo = (shouldHide) => {
    const video = elVideo.current;
    setDesktopActive(!shouldHide);
    shouldHide ? video.classList.remove("active") : video.classList.add("active");
  };

  return (
    <div
      className={`projects__project ${isActiveProject ? "active" : ""}`}
      onMouseEnter={(ev) => showVideo(ev)}
      onMouseMove={(ev) => moveVideo(ev)}
      onMouseLeave={(ev) => hideVideo(ev)}
      key={`project-${index}`}
    >
      <div className="projects__project-top" onClick={(ev) => makeActive(ev)}>
        <div className="projects__project-header">
          <p className="date small">{date}</p>
          <span className={`icon-plus ${isActiveProject ? "active" : ""}`}></span>
        </div>
        <div className="info">
          <h3 className="h3 heading">{title}</h3>
          <p className="description">{description}</p>
          <p className="small">While at {studio}</p>
        </div>
        <div className="projects__project-tags">
          <ProjectLink
            url={url}
            title={url_title}
            onMouseEnter={() => showHideVideo(true)}
            onMouseLeave={() => showHideVideo(false)}
          />
          <div className="tags">
            {tags.map((tag) => (
              <span className="tag">{tag}</span>
            ))}
          </div>
        </div>
      </div>
      <Collapse
        className="projects__project-bottom"
        isOpen={isActiveProject}
        transition={`height 0.3s cubic-bezier(.4, 0, .2, 1)`}
      >
        <ReactPlayer
          url={video.asset.url}
          playsinline={true}
          loop={true}
          muted={true}
          playing={isActiveProject}
          pip={false}
        />
        <ProjectLink url={url} title={url_title} />
      </Collapse>
      <div className="projects__project-video--hover" ref={elVideo}>
        <ReactPlayer url={video.asset.url} loop={true} muted={true} playing={isDesktopActive} />
      </div>
    </div>
  );
};

const ProjectLink = ({ url, title, onMouseEnter, onMouseLeave }) => (
  <a
    href={url}
    target="_blank"
    className="projects__project-link small"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {title}
    <span className="icon-arrow">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 13L13 1M13 1V11M13 1H3" stroke="currentColor" strokeLinecap="square" />
      </svg>
    </span>
  </a>
);

const Projects = ({ heading, projects }) => {
  const [activeProject, setActiveProject] = useState(0);
  return (
    <div className="container projects">
      <h2 className="h2">{heading}</h2>
      <div className="projects__list">
        {projects.map((project, index) => (
          <Project
            index={index}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            project={project}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
