import React from "react";

const Text = ({ heading, content }) => (
  <div className="container section-text">
    <h2 className="h1 section-text__heading">{heading}</h2>
    {content && (
      <div className="section-text__content">
        {content.map(text => (
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        ))}
      </div>
    )}
  </div>
);

export default Text;
