import React from "react";

const Footer = () => (
  <div className="footer container">
    <div class="footer__column contact">
      <p className="h2">
        <a href="mailto:lily@lilyfielding.co.uk" target="_blank">
          <span className="icon">
            <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490">
              <path
                fill="currentColor"
                d="M479.574,78.191H10.425c-0.071,0-0.141,0.007-0.211,0.008l0.001,0.003C4.556,78.315,0,82.925,0,88.617v312.766
                  c0,5.762,4.669,10.426,10.425,10.426h469.149c5.758,0,10.426-4.664,10.426-10.426V88.617C490,82.854,485.332,78.191,479.574,78.191z
                  M454.407,99.043l-138.581,138.58c-0.002,0.002-0.004,0.003-0.006,0.005L245,308.449l-70.82-70.821
                  c-0.004-0.004-0.008-0.007-0.013-0.012L35.594,99.043H454.407z M20.851,113.784l131.215,131.215L20.851,376.215V113.784z
                  M35.594,390.957l131.215-131.216l70.821,70.821c3.629,3.629,9.303,5.439,14.743,0l70.82-70.82l131.215,131.215H35.594z
                  M469.149,376.213L337.935,244.999l131.214-131.214V376.213z
                "
              />
            </svg>
          </span>
          Email
        </a>
        <span className="divider">&#8226;</span>
        <a href="https://twitter.com/lilfieldz" target="_blank">
          <span className="icon">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
              />
            </svg>
          </span>
          Twitter
        </a>
      </p>
    </div>
    <div className="footer__column">
      <p className="h2">&#169; Lily Fielding {new Date().getFullYear()}</p>
    </div>
  </div>
);

export default Footer;
