import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Header from "../components/header";
import ComponentList from "../components/componentList";
import Footer from "../components/footer";

import "../styles/app.css";

export const query = graphql`
  query IndexPageQuery {
    sanityIndex {
      id
      components {
        __typename
        ... on SanityHero {
          _key
          _type
          heading
          headingAnims
          subheading
        }
        ... on SanityPress {
          _key
          _type
          heading
          articles {
            heading
            date
            url
            url_title
          }
        }
        ... on SanityProjects {
          _key
          _type
          heading
          projects {
            date
            description
            studio
            tags
            title
            url
            url_title
            video {
              asset {
                url
                size
              }
            }
          }
        }
        ... on SanityContent {
          _key
          _type
          heading
          content
        }
      }
      seo {
        metaDescription
        metaKeywords
        metaTitle
        openGraphDescription
        openGraphImage {
          asset {
            url
          }
        }
        openGraphTitle
        twitterDescription
        twitterTitle
        twitterImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const Index = props => {
  const { data = {}, errors } = props;
  const { sanityIndex } = data;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const seo = (sanityIndex || {}).seo;
  const components = (sanityIndex || {}).components ? mapEdgesToNodes(sanityIndex.components) : [];

  return (
    <div className="outer-container">
      <Header />
      <SEO {...seo} />
      {components && <ComponentList components={sanityIndex.components} />}
      <Footer />
    </div>
  );
};

export default Index;
