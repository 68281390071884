import React from "react";
import Hero from "./hero";
import Projects from "./projects";
import Press from "./press";
import Text from "./text";

const ComponentList = ({ components }) => {
  console.log(components);
  const modules = components.map((component, index) => {
    let module;
    switch (component.__typename) {
      case "SanityHero":
        module = <Hero key={`component-${index}`} index={index} {...component} />;
        break;
      case "SanityProjects":
        module = <Projects key={`component-${index}`} index={index} {...component} />;
        break;
      case "SanityPress":
        module = <Press key={`component-${index}`} index={index} {...component} />;
        break;
      case "SanityContent":
        module = <Text key={`component-${index}`} index={index} {...component} />;
        break;
    }
    return module;
  });

  return <React.Fragment>{modules}</React.Fragment>;
};

export default ComponentList;
