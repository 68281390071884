import React, { useState } from "react";

const Press = ({ heading, articles }) => {
  const [currentView, setCurrent] = useState(3);

  return (
    <div className="container press">
      <h2 className="h2 heading">{heading}</h2>
      <div className="press__list">
        {articles.map((article, index) => {
          const { date, heading, url, url_title } = article;
          return (
            <a
              key={`article-${index}`}
              href={url}
              target="_blank"
              className={`press__article ${index >= currentView ? "visually-hidden" : ""}`}
            >
              <div className="press__article-date">
                <p className="date small">{date}</p>
              </div>
              <div className="press__article-heading">
                <h3 className="h3 heading">{heading}</h3>
              </div>
              <div className="press__article-link">
                <p className="small">
                  {url_title}
                  <span className="icon-arrow">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13L13 1M13 1V11M13 1H3"
                        stroke="currentColor"
                        strokeLinecap="square"
                      />
                    </svg>
                  </span>
                </p>
              </div>
            </a>
          );
        })}
      </div>
      <button
        className={`press__more h2 ${currentView >= articles.length ? "visually-hidden" : ""}`}
        onClick={() => setCurrent(currentView + 3)}
      >
        Load More
      </button>
    </div>
  );
};

export default Press;
