const vars = () => {
  const isBrowser = typeof window !== "undefined";

  if (!isBrowser) return;

  return {
    "50vh": `${window.innerHeight * 0.5}px`,
    "100vh": `${window.innerHeight}px`
  };
};

export default vars;
